
<template>
  <v-app :style="{ 'background-color': $vuetify.theme.themes.dark.bg }">
    <!-- Format aspect-ratio="4 / 3" -->
    <v-responsive content-class="c-app__layout__faculdade_faces" :aspect-ratio="4 / 3">
      <!--  Maximum width 1440px  -->
      <v-sheet id="c-sheet" max-width="1440px" color="bg2" class="overflow-hidden pa-sm-0 ma-sm-0">

        <v-toolbar color="#d0d8e0" elevation="24" height="60" class="d-xs-block d-md-none">
          <!-- Logo Mobile -->
          <v-img @click="sendLinkImgHome('https://facesbr.com')" src="./assets/logo-faculdade-faces-2.webp"
            height="45px" contain fetchpriority="high" position="left" class="u-cursor"></v-img>
          <v-app-bar-nav-icon color="primary" @click="drawer = true" class="cadevc">
          </v-app-bar-nav-icon>
        </v-toolbar>

        <v-speed-dial fixed v-model="fab" :top="top" :left="left" :right="right" :bottom="bottom"
          class="u-z-index--100 c-speed-dial" transition="slide-y-reverse-transition">
          <template v-slot:activator>
            <v-btn v-model="fab" dark fab class="green accent-4">
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon color="white" large v-else>
                mdi-whatsapp
              </v-icon>
            </v-btn>
          </template>
          <v-btn @click="sendLinkImgHome(msmBolsa)" rounded color="primary" small class="white--text mr-4">Bolsa</v-btn>
          <v-btn @click="sendLinkImgHome(msmInscrição)" rounded small
            class="c-btn-grandiente white--text mr-4">Inscrição</v-btn>
          <v-btn @click="sendLinkImgHome(msmOrientacao)" rounded color="primary" small
            class="white--text mr-7">Orientação</v-btn>
        </v-speed-dial>

        <!-- Menu Desktop e LG -->
        <v-app-bar color="bg" dark flat min-height="538px" height="538px" max-width="1440"
          class="pa-0 ma-0 u-z-index--30 cabecalho d-none d-lg-block" src="@/assets/faculdade-itaborai-.webp">

          <!-- Imagem cabeçalho - Slide -->
          <template v-slot:img="{ props }">
            <v-img eager v-bind="props" lazy-src="@/assets/draft/faculdade-itaborai-draft.webp" cover
              :position="$vuetify.breakpoint.xsOnly ? 'center' : 'center'" fetchpriority="high">

              <v-row justify="space-around" align-content="center" aling="center" class="fill-height ma-0 pa-0">

                <v-col cols="12" md="4" sm="6" class="mt-n16 mb-16 pa-0 mx-auto">
                  <h1 class="text-left primary--text c-titulo-slide">Estude <span class="c-titulo-slide-sub"> na
                      instituição de<br /> ensino</span> mais completa<br />de Itaboraí e região</h1>
                </v-col>

                <v-col cols="12" md="4" sm="6" class="mt-n16 ma-0 pa-0 ml-auto">

                  <h1 class="text-left primary--text c-titulo-slide">No presencial <span class="c-titulo-slide-sub">ou
                      no</span> EAD<br /><span class="c-titulo-slide-sub">a</span> FACES <span
                      class="c-titulo-slide-sub">estará com você!</span></h1>

                  <v-btn link @click="sendLinkImgHome(msmBolsa)" rounded
                    class="c-btn-bolsa u-z-index--100 primary--text c-btn-grandiente text-subtitle-1 mt-2 px-10">Concorra
                    a bolsa de até 100%</v-btn>
                </v-col>

              </v-row>

            </v-img>

          </template>

          <!-- Menu Faces -->

          <!-- Logo -->
          <v-row justify="space-between" class="px-0 pt-5 mx-sm-auto">

            <v-col cols="12" xs="12" md="3" sm="4">
              <v-img @click="sendLinkImgHome('https://facesbr.com')" src="./assets/logo-faculdade-faces.png"
                height="55px" contain fetchpriority="high" position="left" class="u-cursor"></v-img>
            </v-col>

            <!-- Botões do cabecalho -->
            <v-col cols="12" xs="12" sm="9" md="9"
              class="flex-md-row justify-md-center justify-md-center d-md-flex d-none">

              <v-btn plain class="u-btn-hover" @click="sendLinkImgHome('https://facesbr.com/conheca-a-faces')" large
                rounded text color="primary"><v-img max-height="44" max-width="56"
                  src="./assets/icones/faculdade-itaborai-icon.png"></v-img>
                <h3 class="pl-2 c-text-menu text-capitalize">SOBRE A FACES</h3>
              </v-btn>

              <v-btn id="link-todos-cursos" href="#Cursos" plain large rounded color="primary" text
                class="u-btn-hover px-md-10"><v-img max-height="40" max-width="40"
                  src="./assets/icones/diploma-itaborai-icon.png"></v-img>
                <h3 class="pl-2 c-text-menu text-capitalize">CURSOS</h3>
              </v-btn>
              <v-btn @click="sendLinkImgHome(msmInscrição)" plain large rounded color="primary" text class="u-btn-hover d-none d-sm-block
                "><v-img max-height="43" max-width="43"
                  src="./assets/icones/whatsapp-faculdade-itaborai-icon.png"></v-img>
                <h3 class="pl-2 c-text-menu text-capitalize">CONTATO</h3>
              </v-btn>
            </v-col>
          </v-row>
        </v-app-bar>

        <!-- Menu Mobile -->
        <v-app-bar color="bg" dark flat min-height="538px" height="538px" max-width="1440"
          class="pa-0 ma-0 u-z-index--30 cabecalho d-xs-block d-lg-none" src="@/assets/faculdade-itaborai-5.webp">

          <v-navigation-drawer right color="rgb(205 213 221 / 65%)" class="elevation-20" app v-model="drawer" temporary>
            <v-list nav dense>
              <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
                <v-list-item>
                  <v-list-item-icon>
                    <v-img max-height="30" max-width="30"
                      src="./assets/icones/faculdade-itaborai-icon-white.png"></v-img>
                  </v-list-item-icon>
                  <v-list-item-title @click="sendLinkImgHome('https://facesbr.com/conheca-a-faces')">SOBRE A FACES</v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-img max-height="30" max-width="30" contain src="./assets/icones/diploma-itaborai-icon-white.png"></v-img>
                  </v-list-item-icon>
                  <v-list-item-title @click="setarLinkBox('Cursos Técnicos', 'menu-mobile')">CURSOS</v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-img max-height="25" max-width="25" contain
                      src="./assets/icones/whatsapp-faculdade-itaborai-icon-white.png"></v-img>
                  </v-list-item-icon>
                  <v-list-item-title @click="sendLinkImgHome(msmInscrição)">CONTATO</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-row justify="center">
              <v-col cols="2" xs="2">
                <v-icon @click="sendLinkImgHome('https://www.instagram.com/facesbr')" color="primary" large>mdi-instagram</v-icon>
              </v-col>
              <v-col cols="2" xs="2">
                <v-icon @click="sendLinkImgHome('https://www.facebook.com/faculdadecooperar')" color="primary" large>mdi-facebook</v-icon>
              </v-col>
            </v-row>
          </v-navigation-drawer>

          <!-- Imagem cabeçalho - Slide -->
          <template v-slot:img="{ props }">
            <v-img eager v-bind="props" lazy-src="@/assets/draft/faculdade-itaborai-draft-2.webp" cover
              :position="$vuetify.breakpoint.xsOnly ? 'center' : 'center'" fetchpriority="high">

              <v-row justify="center" align-content="center" aling="center" class="fill-height ma-0 pa-0">

                <v-col cols="12" sm="12" class="">
                  <h1 class="text-left primary--text c-titulo-slide-mobile pa-5">Estude <span
                      class="c-titulo-slide-sub"> na
                      instituição de<br /> ensino</span> mais completa<br />de Itaboraí e região</h1>
                </v-col>

                <v-col cols="12" md="4" sm="12" class="">

                  <h1 class="text-left primary--text c-titulo-slide-mobile pa-5">No presencial <span
                      class="c-titulo-slide-sub">ou
                      no</span> EAD <span class="c-titulo-slide-sub">a</span> FACES <span
                      class="c-titulo-slide-sub">estará com você!</span></h1>

                  <v-btn link @click="sendLinkImgHome(msmBolsa)" rounded
                    class="c-btn-bolsa u-z-index--100 primary--text c-btn-grandiente text-subtitle-1 mt-2 px-10">Concorra
                    a bolsa de até 100%</v-btn>
                </v-col>

              </v-row>

            </v-img>
          </template>
          <!-- Menu Faces -->
          <!-- Logo e botões -->
          <v-row justify="space-between" class="px-0 pt-5 mx-sm-auto">
          </v-row>
        </v-app-bar>

        <!-- Main content -->
        <v-main id="c-main" class="conteudo-principal">
          <v-container class="fill-height" fluid>

            <!-- Contêiner dos box iniciais Desktop -->
            <v-row justify="center" align-content="start" align="start" class="mb-0 pb-0 c-row-box d-none d-md-flex">

              <!--  Primeiro Box -->
              <v-col cols="12" lg="3" sm="3" class="
                  c-col-box 
                  ma-0
                  pa-0">
                <v-img eager src="./assets/box/cursos-tecnico-em-itaborai.webp"
                  lazy-src="@/assets/box/draft/cursos-tecnico-em-itaborai-draft.webp" min-width="320px" contain
                  :position="$vuetify.breakpoint.xsOnly ? 'center' : 'top'" fetchpriority="high" class="
                    ma-0
                    pa-0">
                </v-img>
                <div class="ma-0 pa-0">
                  <h2 @click="setarLinkBox('Cursos Técnicos')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-4 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos</span><br />Técnicos</h2>
                  <h2 @click="setarLinkBox('Graduação')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-2 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos
                      de</span><br />Graduação</h2>
                </div>
              </v-col>

              <!--  Segundo Box -->
              <v-col cols="12" lg="3" sm="3" class="
                  c-col-box 
                  ma-0
                  pa-0">
                <v-img eager src="./assets/box/cursos-de-graduacao-em-itaborai.webp"
                  lazy-src="@/assets/box/draft/cursos-de-graduacao-em-itaborai-draft.webp" min-width="320px" contain
                  :position="$vuetify.breakpoint.xsOnly ? 'center' : 'top'" fetchpriority="high" class="
                    ma-0
                    pa-0">
                </v-img>
                <div class="ma-0 pa-0">
                  <h2 @click="setarLinkBox('Capacitação Profissional')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-4 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos
                      de</span><br />Capacitação<br />profissional</h2>
                  <h2 @click="setarLinkBox('Pós-Graduação')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-2 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Especialização</span><br />Pós-Graduação</h2>
                  <h2 @click="setarLinkBox('Cursos Preparatórios')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-2 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos</span><br />Preparatórios</h2>
                </div>
              </v-col>

              <!--  Terceiro Box -->
              <v-col cols="12" lg="3" sm="3" class="
                  c-col-box 
                  ma-0
                  pa-0">
                <v-img eager src="./assets/box/curso-avancado-de-teologia.webp"
                  lazy-src="@/assets/box/draft/curso-avancado-de-teologia-draft.webp" min-width="320px" contain
                  :position="$vuetify.breakpoint.xsOnly ? 'center' : 'top'" fetchpriority="high" class="
                    ma-0
                    pa-0">
                </v-img>
                <div class="ma-0 pa-0">
                  <h2 @click="setarLinkBox('Especialização Técnicas - Pós Técnico')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-4 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos
                      de</span><br />Especialização<br />técnica</h2>
                  <h2 @click="setarLinkBox('Cursos Avançados em Teologia')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-2 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos</span><br />Avançado em teologia</h2>
                </div>
              </v-col>

              <!--  Elemento de bg atras dos box -->
              <v-col :style="{ 'background-color': $vuetify.theme.themes.dark.bg2 }" cols="12" lg="12" sm="12" class="
                  c-col-box 
                  ma-0
                  u-p-absolute
                  u-elemento-1">
              </v-col>
            </v-row>

            <!-- Contêiner dos box iniciais Mobile -->
            <v-row justify="center" align-content="start" align="start"
              class="mb-0 pb-0 c-row-box d-xs-block d-md-none">

              <!--  Primeiro Box (Img da jovem) -->
              <v-col cols="12" xs="6" class="">
                <v-img eager src="./assets/box/cursos-tecnico-em-itaborai-2.webp"
                  lazy-src="@/assets/box/draft/cursos-tecnico-em-itaborai-draft.webp" min-width="320px" contain
                  :position="$vuetify.breakpoint.xsOnly ? 'center' : 'top'" fetchpriority="high" class="ma-0 pa-0">
                </v-img>
                <div class="mt-n3">

                  <h2 @click="setarLinkBox('Capacitação Profissional')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-4 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos
                      de</span><br />Capacitação<br />profissional</h2>

                  <h2 @click="setarLinkBox('Cursos Preparatórios')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-2 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos</span><br />Preparatórios</h2>

                  <h2 @click="setarLinkBox('Cursos Técnicos')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-4 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos</span><br />Técnicos</h2>

                  <h2 @click="setarLinkBox('Especialização Técnicas - Pós Técnico')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-4 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos
                      em</span><br />Especialização<br />técnica</h2>

                  <h2 @click="setarLinkBox('Graduação')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-2 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos
                      de</span><br />Graduação</h2>

                </div>
              </v-col>
              <!--  Segundo box (img do rapaz )-->
              <v-col cols="12" xs="6" class="c-col-box">
                <v-img eager src="./assets/box/curso-avancado-de-teologia-2.webp"
                  lazy-src="@/assets/box/draft/curso-avancado-de-teologia-draft.webp" min-width="340px" contain
                  :position="$vuetify.breakpoint.xsOnly ? 'center' : 'top'" fetchpriority="high" class="ma-0 pa-0">
                </v-img>
                <div class="ma-0">

                  <h2 @click="setarLinkBox('Pós-Graduação')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-2 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Especialização</span><br />Pós-Graduação</h2>

                  <h2 @click="setarLinkBox('Cursos Avançados em Teologia')"
                    class="primary--text c-titulo pl-5 text-uppercase pt-2 u-btn-hover u-cursor"><span
                      class="c-titulo-sub">Cursos</span><br />Avançado em teologia</h2>
                </div>
              </v-col>
            </v-row>

            <!-- Motivos para estudar na faces DESKTOP -->
            <v-row justify="center" align-content="center" class="pb-16 pt-14 u-z-index--10 d-none d-md-flex">

              <!-- DISPOMOS DA MELHOR ESTRUTURA PARA CAPACITAÇÃO EM ITABORAÍ -->
              <v-col cols="12" sm="12" class="pb-10">

                <v-hover v-slot="{ hover }">
                  <div>

                    <v-img transition="scroll-y-reverse-transition" v-if="!hover" eager
                      src="./assets/faculdade-faces-em-itaborai.webp" min-width="200px" height="280px" contain
                      :position="$vuetify.breakpoint.xsOnly ? 'center' : 'center'" fetchpriority="high"
                      class="c-img-unidade-faces u-cursor ma-0 pa-0 u-z-index--20">
                    </v-img>


                    <v-img v-if="hover" src="./assets/faculdade-faces-em-itaborai-ori.webp" min-width="200px"
                      height="280px" contain :position="$vuetify.breakpoint.xsOnly ? 'center' : 'center'"
                      class="c-img-unidade-faces u-cursor ma-0 pa-0 u-z-index--20">
                    </v-img>

                  </div>
                </v-hover>

                <h2 class="primary--text text-center c-titulo-motivo">Dispomos da <span class="c-titulo-motivo-sub">
                    melhor<br />estrutura</span> para<br />capacitação <span class="c-titulo-motivo-sub">em
                    Itaboraí</span></h2>
              </v-col>

              <!-- Contador 1 -->
              <v-col cols="12" sm="12" md="3" lg="3">
                <div v-intersect="contadorEmpresaLoop" class="text-center"><v-progress-circular :rotate="360"
                    :size="130" :width="10" :value="contadorEmpresa" color="secondary">
                    <p class="text-h4 pa-0 ma-0">{{ contadorEmpresa }}<span class="text-h6">mil</span></p>
                  </v-progress-circular>
                </div>
                <h4 class="primary--text text-center c-titulo-motivo-2"><span
                    class="c-titulo-motivo-sub-2">Empresas</span> parceiras<span class="c-titulo-motivo-sub-2">
                    cadastradas</span></h4>
              </v-col>

              <!-- Contador 2 -->
              <v-col cols="12" sm="12" md="3" lg="3">
                <div v-intersect="contadorAlunoLoop" class="text-center"><v-progress-circular :rotate="360" :size="130"
                    :width="10" :value="contadorAlunos" color="secondary">
                    <p class="text-h4 pa-0 ma-0">{{ contadorAlunos }}%</p>
                  </v-progress-circular>
                </div>
                <h4 class="primary--text text-center c-titulo-motivo-2">Dos <span class="c-titulo-motivo-sub-2">nossos
                    alunos atuando no</span> mercado de <span class="c-titulo-motivo-sub-2">trabalho</span></h4>
              </v-col>

              <!-- Contador 3 -->
              <v-col cols="12" sm="12" md="3" lg="3">
                <div v-intersect="contadorAlunoLoop" class="text-center">
                  <v-img src="./assets/professor-premiado-faculdade-faces.webp" min-width="50px" height="140" contain
                    :position="$vuetify.breakpoint.xsOnly ? 'center' : 'center'" class="mt-4">
                  </v-img>
                </div>
                <h4 class="primary--text text-center c-titulo-motivo-2 pt-5">Corpo docente <span
                    class="c-titulo-motivo-sub-2">premiado</span></h4>
              </v-col>
            </v-row>

            <!-- Motivos para estudar na faces MOBILE -->
            <v-row justify="center" align-content="center" class="mt-n8 u-z-index--10 d-xs-block d-md-none">

              <!-- DISPOMOS DA MELHOR ESTRUTURA PARA CAPACITAÇÃO EM ITABORAÍ -->
              <v-col cols="12" sm="12" class="pb-4">

                <v-hover v-slot="{ hover }">
                  <div>

                    <v-img transition="scroll-y-reverse-transition" v-if="!hover" eager
                      src="./assets/faculdade-faces-em-itaborai.webp" width="700px" min-height="280px" contain
                      :position="$vuetify.breakpoint.xsOnly ? 'center' : 'center'" fetchpriority="high"
                      class="c-img-unidade-faces u-cursor ma-0 pa-0 u-z-index--20">
                    </v-img>


                    <v-img v-if="hover" src="./assets/faculdade-faces-em-itaborai-ori.webp" min-width="200px"
                      height="280px" contain :position="$vuetify.breakpoint.xsOnly ? 'center' : 'center'"
                      class="c-img-unidade-faces u-cursor ma-0 pa-0 u-z-index--20">
                    </v-img>

                  </div>
                </v-hover>

                <h2 class="primary--text text-center c-titulo-motivo-mobile mt-n10">Dispomos da <span
                    class="c-titulo-motivo-sub">
                    melhor<br />estrutura</span> para<br />capacitação <span class="c-titulo-motivo-sub">em
                    Itaboraí</span></h2>
              </v-col>

              <!-- Contador 1 -->
              <v-col cols="12" sm="12" md="3" lg="3">
                <div v-intersect="contadorEmpresaLoop" class="text-center"><v-progress-circular :rotate="360"
                    :size="130" :width="10" :value="contadorEmpresa" color="secondary">
                    <p class="text-h4 pa-0 ma-0">{{ contadorEmpresa }}<span class="text-h6">mil</span></p>
                  </v-progress-circular>
                </div>
                <h4 class="primary--text text-center c-titulo-motivo-2"><span
                    class="c-titulo-motivo-sub-2">Empresas</span> parceiras<span class="c-titulo-motivo-sub-2">
                    cadastradas</span></h4>
              </v-col>

              <!-- Contador 2 -->
              <v-col cols="12" sm="12" md="3" lg="3">
                <div v-intersect="contadorAlunoLoop" class="text-center"><v-progress-circular :rotate="360" :size="130"
                    :width="10" :value="contadorAlunos" color="secondary">
                    <p class="text-h4 pa-0 ma-0">{{ contadorAlunos }}%</p>
                  </v-progress-circular>
                </div>
                <h4 class="primary--text text-center c-titulo-motivo-2">Dos <span class="c-titulo-motivo-sub-2">nossos
                    alunos atuando no</span> mercado de <span class="c-titulo-motivo-sub-2">trabalho</span></h4>
              </v-col>

              <!-- Contador 3 -->
              <v-col cols="12" sm="12" md="3" lg="3">
                <div v-intersect="contadorAlunoLoop" class="text-center">
                  <v-img src="./assets/professor-premiado-faculdade-faces.webp" min-width="50px" height="140" contain
                    :position="$vuetify.breakpoint.xsOnly ? 'center' : 'center'" class="mt-4">
                  </v-img>
                </div>
                <h4 class="primary--text text-center c-titulo-motivo-2 pt-5">Corpo docente <span
                    class="c-titulo-motivo-sub-2">premiado</span></h4>
              </v-col>
            </v-row>

            <!-- Cursos Técnicos -->
            <v-row :style="{ 'background-color': $vuetify.theme.themes.dark.bg2 }" justify="center"
              align-content="center" class="pb-16 pt-16 px-16 d-none d-lg-flex">

              <!-- Cursos Técnicos Título -->
              <v-col cols="12" lg="6" sm="6" class="ma-0 pa-10">
                <h2 class="text-h2 primary--text text-uppercase">Cursos<br />Técnicos</h2>
                <h3 class="pt-2 text-h6 primary--text text-uppercase"><strong>Clique</strong> no curso de
                  interesse<br /> para mais <strong>informações, inscrição e bolsa.</strong></h3>
              </v-col>

              <!-- Cursos Técnicos Imagem de Destaque -->
              <v-col cols="12" lg="6" sm="6">
                <v-img src="./assets/box/curso-de-enfermagem-em-itaborai.webp"
                  lazy-src="@/assets/box/draft/curso-de-enfermagem-em-itaborai-draft.webp" min-height="300px"
                  max-height="300px" contain :position="$vuetify.breakpoint.xsOnly ? 'top' : 'top'" fetchpriority="high"
                  id="c-img-cursos-tecnicos" class="ma-0 mt-n13 pa-0">
                </v-img>
              </v-col>

              <!-- Tab de cursos técnicos -->
              <v-col cols="12" sm="12" class="ma-0 pa-5">
                <v-card color="bg" light id="c-tab-cursos" class="rounded-lg pa-4">
                  <v-tabs vertical class="c-tab">

                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Administração
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      ENFERMAGEM
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Radiologia
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Estética
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Podologia
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Aquicultura
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Secretaria
                    </v-tab>
                    <v-tab-item>
                      <v-card light flat>
                        <!-- Administração -->
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Administração</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">
                            O Curso <strong>Técnico em Administração</strong> a ser formado deverá desenvolver
                            competências e habilidades para executar atividades administrativas e coordenar equipes em
                            atividades correlatas, no nível operacional, dos setores de produção e serviços,
                            utilizando-se de técnicas e tecnologias apropriadas e de padrões éticos, legais, de
                            qualidade, e segurança, com responsabilidade social e ambiental.</p>

                          <v-btn @click="linkTab(1, 'Administração')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(2, '', 'https://colegiofaces.com/curso/administracao/')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Administração')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Enfermagem -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Enfermagem</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">O Curso de <strong>Técnico em Enfermagem</strong> visa cumprir às
                            exigências
                            específicas da ocupação e da área de Saúde. Incorpora inovações decorrentes dos avanços
                            científicos e tecnológicos deste segmento, da experiência acumulada pela Instituição na
                            oferta desta habilitação e de novas tecnologias educacionais.
                            Segundo a Classificação Brasileira de Ocupações – CBO, este profissional prognostica e trata
                            de doentes , utilizando-se de técnicas aprendidas no curso em laboratórios e hospitais. O
                            exercício dessa ocupação requer curso técnico de nível médio na área de atuação.</p>

                          <v-btn @click="linkTab(1, 'Enfermagem')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(2, '', 'https://colegiofaces.com/curso/tecnicoemenfermagem/')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Enfermagem')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Radiologia  -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Radiologia</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">
                            O Curso <strong>Técnico em Radiologia</strong> visa cumprir às exigências específicas da
                            ocupação e da área de Saúde. Incorpora inovações decorrentes dos avanços científicos e
                            tecnológicos deste segmento, da experiência acumulada pela Instituição na oferta desta
                            habilitação e de novas tecnologias educacionais.</p>

                          <v-btn @click="linkTab(1, 'Radiologia')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(1, 'Radiologia')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Radiologia')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Estética -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Estética</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">
                            O Curso <strong>Técnico em Estética</strong> visa promover a formação profissional técnica
                            de nível médio para profissionais da educação que atuam em áreas do embelezamento, promoção,
                            proteção, manutenção e recuperação estética da pele.</p>

                          <v-btn @click="linkTab(1, 'Estética')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(2, '', 'https://colegiofaces.com/curso/estetica/')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Estética')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Podologia -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Podologia</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">
                            O Curso <strong>Técnico em Podologia</strong> visa cumprir às exigências específicas da
                            ocupação e da área de Saúde. Incorpora inovações decorrentes dos avanços científicos e
                            tecnológicos deste segmento, da experiência acumulada pela Instituição na oferta desta
                            habilitação e de novas tecnologias educacionais.</p>

                          <v-btn @click="linkTab(1, 'Podologia')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(2, '', 'https://colegiofaces.com/curso/podologia/')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Podologia')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Aquicultura  -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Aquicultura</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">
                            A estruturação curricular do curso <strong>Técnico em Aquicultura</strong> a ser formado
                            deverá desenvolver
                            competências e habilidades para executar atividades administrativas e coordenar equipes em
                            atividades correlatas, no nível operacional, dos setores de produção e serviços,
                            utilizando-se de técnicas e tecnologias apropriadas e de padrões éticos, legais, de
                            qualidade, e segurança, com responsabilidade social e ambiental.</p>

                          <v-btn @click="linkTab(1, 'Técnico em Aquicultura')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(2, '', 'https://colegiofaces.com/curso/aquicultura/')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Técnico em Aquicultura')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Secretaria  -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Secretaria Escolar</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire</v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">
                            O Curso <strong>Técnico em Secretaria Escolar</strong> foi desenvolvido para formar
                            profissionais qualificados para contribuir com a gestão escolar, atuando na organização de
                            registros escolares. Durante o curso, o aluno será preparado para desenvolver processos de
                            matrícula e transferência de estudantes; de organização de turmas e de registros do
                            histórico dos alunos. Além disso, será habilitado a organizar e controlar arquivos com
                            lançamentos da vida acadêmica, processos de conclusão de cursos e colação de grau, entre
                            outras.</p>

                          <v-btn @click="linkTab(1, 'Secretaria Escolar')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(2, '', 'https://colegiofaces.com/curso/secretariaescolar/')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Secretaria Escolar')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </v-col>
            </v-row>

            <!-- Pós Graduação -->
            <v-row :style="{ 'background-color': $vuetify.theme.themes.dark.primaryMn }" justify="center"
              align-content="center" class="pa-0 pb-0 d-none d-lg-flex">
              <v-col cols="12" sm="12" class="pa-0 ma-0"></v-col>

              <!-- Pós Graduação Imagem de Destaque -->
              <v-col cols="12" lg="6" sm="6">
                <v-img src="./assets/box/curso-de-economia-em-itaborai.webp"
                  lazy-src="@/assets/box/draft/curso-de-economia-em-itaborai-draft.webp" min-height="300px"
                  max-height="300px" contain :position="$vuetify.breakpoint.xsOnly ? 'top' : 'top'" fetchpriority="high"
                  id="c-img-cursos-tecnicos" class="ma-0 mt-n13 pa-0">
                </v-img>
              </v-col>

              <!-- Pós Graduação Título -->
              <v-col cols="12" lg="6" sm="6" class="ma-0 pa-10">
                <h2 class="text-h2 primary--text text-uppercase">Pós-Graduação<br />Presencial</h2>
                <h3 class="pt-2 text-h6 primary--text text-uppercase"><strong>Clique</strong> no curso de
                  interesse<br /> para mais <strong>informações, inscrição e bolsa.</strong></h3>
              </v-col>

              <!-- Tab de Pós Graduação -->
              <v-col cols="12" sm="12" class="ma-0 pa-5 px-16">
                <v-card color="bg" light id="c-tab-cursos-2" class="rounded-lg pa-2">
                  <v-tabs vertical class="c-tab">
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      ECONOMIA
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Biossegurança
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Filosofia
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Teologia
                    </v-tab>
                    <v-tab class="c-tab-menu mt-4 pt-4">
                      <v-icon left>
                        mdi-school
                      </v-icon>
                      Enfermagem
                    </v-tab>
                    <v-tab-item>
                      <v-card light flat>
                        <!-- Economia -->
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">MBA em Economia, Finanças Digitais e Mercado</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta</v-btn>
                          <p class="text-h6 pb-2">
                            O curso foi desenvolvido para os profissionais da área de investimento, empreendedorismo,
                            gestão e demais estudiosos de maneira a instrumentalizá-los tanto em perspectiva histórica
                            de maneira que possam entender as causas das principais mudanças quanto de modo a perceber a
                            transição de uma economia material para uma economia digital que se utiliza de diversos
                            recursos tecnológicos com o intuito de agilizar a produção e a distribuição de produtos e
                            serviços mediante um determinado meio digital.</p>

                          <v-btn @click="linkTab(1, 'MBA em Economia, Finanças Digitais e Mercado')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn
                            @click="linkTab(2, '', 'https://facesbr.com/mba-em-economia-financas-digitais-e-mercados/')"
                            rounded class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'MBA em Economia, Finanças Digitais e Mercado')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Biossegurança  -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Pós-Graduação em Biossegurança em Serviço de
                            Saúde, Segurança do Trabalho e Meio Ambiente</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">A <strong>Biossegurança</strong> é um conjunto de ações para
                            controlar, prevenir, diminuir ou extinguir todos os riscos à saúde humana, animal e ao meio
                            ambiente. Representa um importante campo de trabalho e estudo, devido a sua relação com
                            inúmeras atividades profissionais em estabelecimentos de saúde, indústrias químicas e
                            biotecnológicas, além de diversos setores produtivos que, por conta dos riscos ocupacionais,
                            usam a Biossegurança em seus programas de prevenção de acidentes.

                            Dessa forma, o curso busca formar profissionais com diferentes habilidades para atuar na
                            área de Biossegurança, de modo abrangente, focando em todas as vertentes que agregam a área
                            da segurança no trabalho. Abordará também questões éticas e bioéticas para que os alunos
                            sejam capazes de perceber os riscos ocupacionais aos quais estão expostos e permitir que
                            sejam capazes de implantar as medidas de segurança mais adequadas as situações de risco.</p>

                          <v-btn
                            @click="linkTab(1, 'Pós-Graduação em Biossegurança em Serviço de Saúde, Segurança do Trabalho e Meio Ambiente')"
                            rounded class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn
                            @click="linkTab(2, '', 'https://facesbr.com/produto/pos-graduacao-em-biosseguranca-em-servico-de-saude-seguranca-do-trabalho-e-meio-ambiente/')"
                            rounded class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn
                            @click="linkTab(1, 'Pós-Graduação em Biossegurança em Serviço de Saúde, Segurança do Trabalho e Meio Ambiente')"
                            rounded x-large class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Filosofia  -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Pós-Graduação em Filosofia, Sociologia e Cultura.
                          </h2>
                          <p class="text-h6 pb-2">
                            O <strong>Ensino de Filosofia</strong> ocupa um papel de grande relevância na construção do
                            pensamento humano
                            e na própria percepção de mundo da sociedade. A Filosofia se caracteriza como uma atitude
                            humana que envolve a reflexão crítica da realidade.

                            O estudo filosófico visa capacitar o aluno a formular e propor soluções de problemas em
                            todas as áreas de conhecimento, a desenvolver consciência crítica em relação às
                            circunstâncias históricas, sociais e políticas em que a realidade se apresenta e também a
                            compreender questões relativas à própria existência como pessoa.

                            Para tanto, o ensino de Filosofia, associado a uma abordagem científica a respeito da
                            sociedade e da cultura, visa aproximar o conteúdo que emana do saber filosófico à produção
                            da ciência, da arte, da religião, da política e das ações ligadas ao desenvolvimento
                            pessoal. O objetivo é de promover a formação integração da pessoa em relação à cidadania, do
                            respeito à dignidade da pessoa humana e da defesa dos Direitos Humanos.</p>

                          <v-btn @click="linkTab(1, 'Pós-Graduação em Filosofia, Sociologia e Cultura.')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(1, 'Pós-Graduação em Filosofia, Sociologia e Cultura.')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Pós-Graduação em Filosofia, Sociologia e Cultura.')" rounded
                            x-large class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Teologia  -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Pós-Graduação em Teologia Sistemático-Pastoral
                          </h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta</v-btn>
                          <p class="text-h6 pb-2">
                            O curso de <strong>Pós-Graduação Lato Sensu em Teologia Pastoral-Sistemática</strong> tem
                            como finalidade
                            formar especialistas em Teologia que possam atuar junto a grupos, comunidades e organizações
                            comunitárias cristãs. O campo teológico tem uma ampla área de aplicações, sobretudo no que
                            diz respeito à capacitação de lideranças para comunidades religiosas e organizações sem fins
                            lucrativos.

                            O teólogo é um profissional com formação acadêmica abrangente, com conteúdos que envolvem
                            desde o conhecimento ligado ao comportamento social e humano até a compreensão e análise dos
                            textos bíblicos. A região na qual a FACES está inserida é marcada pela presença
                            significativa de organizações, igrejas e associações comunitárias que demandam pessoas com
                            habilidades para lidar com as necessidades socioeconômicas e humanas de sua população a
                            partir de uma hermenêutica centrada numa abordagem contextualizada.</p>

                          <v-btn @click="linkTab(1, 'Pós-Graduação em Teologia Sistemático-Pastoral')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(1, 'Pós-Graduação em Teologia Sistemático-Pastoral')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Pós-Graduação em Teologia Sistemático-Pastoral')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Enfermagem -->
                    <v-tab-item>
                      <v-card light flat>
                        <v-card-text>
                          <h2 class="primary--text text-h3 pa-3 mb-0">Pós-Graduação em Enfermagem Hospitalista</h2>
                          <v-btn color="orange" text class="u-text-initial subtitle-1 darken-1--text mt-n4 mb-4"><v-icon
                              left large class="c-icon">mdi-fire </v-icon>Curso em alta, com alta
                            empregabilidade</v-btn>
                          <p class="text-h6 pb-2">
                            O curso visa os cuidados com o cliente em unidade de internação hospitalar, onde este está
                            cada vez mais complexo, seja pelo atual cenário epidemiológico – em que as doenças crônicas
                            não transmissíveis (DCNTs) são responsáveis por 70% das mortes no mundo, segundo a
                            Organização Mundial de Saúde (OMS) –, ou pelo avanço científico e tecnológico dos
                            diagnósticos.

                            Esta mudança de cenário, vista não apenas nos Centros de Terapia Intensiva, mas nas áreas de
                            internação abertas, pede por equipes qualificadas, de forma técnica e humanística.</p>

                          <v-btn @click="linkTab(1, 'Pós-Graduação em Enfermagem Hospitalista')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Inscrição<v-icon right
                              class="c-icon">mdi-whatsapp</v-icon></v-btn>

                          <v-btn @click="linkTab(1, 'Pós-Graduação em Enfermagem Hospitalista')" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Mais Informações<v-icon right
                              samll>mdi-information-outline</v-icon></v-btn>

                          <v-btn @click="linkTab(3)" rounded
                            class="subtitle-2 c-btn-grandiente primary--text mr-5">Concorrer a Bolsa<v-icon
                              right>mdi-seal </v-icon></v-btn>

                          <v-divider class="my-7"></v-divider>
                          <h3 class="primary--text text-h4 pa-3 mb-0">Deseja receber mais informações deste curso direto
                            de um orientardor ?</h3>
                          <v-btn @click="linkTab(1, 'Pós-Graduação em Enfermagem Hospitalista')" rounded x-large
                            class="text-h5 c-btn-grandiente primary--text pr-7">Chame em nosso
                            Whatsapp<v-icon x-large right class="pl-4">mdi-whatsapp</v-icon></v-btn>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </v-col>
            </v-row>

            <!-- Tabela Cursos -->
            <v-row id="Cursos" :style="{ 'background-color': $vuetify.theme.themes.light.bg2 }" justify="center"
              align-content="center" class="pt-3 pb-2">
              <v-col :style="{ 'background-color': $vuetify.theme.themes.light.bg }" cols="12" sm="12" md="11"
                class="ma-0 pa-0 rounded-lg">
                <TabelaCursos :whatsAppTable="whatsApp" :linkBox="linkBox"></TabelaCursos>
              </v-col></v-row>

            <!--  Footer Contato -->
            <v-row justify="center" align-content="center" class="c-footer"><v-col cols="12" sm="12">
                <Footer :linkInscricao="msmInscrição"></Footer>
              </v-col></v-row>

          </v-container>
        </v-main>
        <div class="c-design-el-7__ct"><span class="c-design-el-7"></span></div>
      </v-sheet>
    </v-responsive>
  </v-app>
</template>

<script>
import TabelaCursos from "./components/TabelaCursos.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: { TabelaCursos, Footer },
  data: () => ({
    drawer: false,
    group: null,
    linkBox: 'Graduação',
    whatsApp: '5521972736161',
    novoWhatsApp: '',
    msmBolsa: '',
    msmInscrição: '',
    msmOrientacao: '',
    msmInscricaoCurso: '',
    cursoSelecionado: '',
    top: false,
    fab: false,
    left: false,
    right: true,
    bottom: true,
    intervalAluno: {},
    intervalEmpresa: {},
    contadorAlunos: 0,
    contadorEmpresa: 0,
    alturaScroll: window.pageYOffset,
    isVisible: false,
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email inválido.";
      },
    },
  }),
  watch: {
    whatsApp: {
      handler(novo) {
        this.msmBolsa = `https://wa.me/${this.whatsApp}?text=N%C3%A3o+apague+essa+mensagem%3A+%2AOl%C3%A1%2C+gostaria+de+informa%C3%A7%C3%B5es+sobre+Bolsa+de+Estudo%2A`

        this.msmInscrição = `https://wa.me/${this.whatsApp}?text=N%C3%A3o+apague+essa+mensagem%3A+%2AOl%C3%A1%2C+gostaria+de+informa%C3%A7%C3%B5es+para+inscri%C3%A7%C3%A3o+em+um+curso%2A`

        this.msmOrientacao = `https://wa.me/${this.whatsApp}?text=N%C3%A3o+apague+essa+mensagem%3A+%2AOl%C3%A1%2C+estou+precisando+de+orienta%C3%A7%C3%A3o%2A`
        this.novoWhatsApp = novo
      },
      immediate: true
    },
  },
  methods: {
    linkTab(op, curso, link) {
      if (op === 1) {
        this.cursoSelecionado = curso
        this.msmInscricaoCurso = `https://wa.me/${this.whatsApp}?text=N%C3%A3o+apague+essa+mensagem%3A+%2AOl%C3%A1%2C+gostaria+de+informa%C3%A7%C3%B5es+para+inscri%C3%A7%C3%A3o+no+curso+de+${this.cursoSelecionado}%2A`
        window.open(this.msmInscricaoCurso, '_blank');
        return
      }
      if (op === 2) {
        window.open(link, '_blank');
        return
      }
      if (op === 3) {
        window.open(this.msmBolsa, '_blank');
        return
      }
      if (op === 4) {
        window.open(this.msmInscrição, '_blank');
        return
      }
    },
    setarLinkBox(formacao, target) {
      this.linkBox = formacao
      document.getElementById('link-todos-cursos').click()
      if(target == 'menu-mobile'){
        this.drawer = false
      }
    },
    sendLinkImgHome(link) {
      window.open(link, '_blank');
    },
    sendMsmBolsa(curso) {
      this.msmInscricaoCurso = `https://wa.me/${this.whatsApp}?text=Não apague essa mensagem: *Olá, gostaria de informações para inscrição no curso de ${curso}*`
    },
    contadorEmpresaLoop(entries) {
      if (entries[0].isIntersecting) {
        this.intervalEmpresa = setInterval(() => {
          if (this.contadorEmpresa === 185) {
            clearInterval(this.intervalEmpresa)
            return
          }
          this.contadorEmpresa += 5
        }, 200)
      }
    },
    contadorAlunoLoop(entries) {
      if (entries[0].isIntersecting) {
        this.intervalAluno = setInterval(() => {
          if (this.contadorAlunos === 68) {
            clearInterval(this.intervalAluno)
            return
          }
          this.contadorAlunos += 4
        }, 300)
      }

    },
    scrollToTop(getTarget) {
      document.getElementById(getTarget).scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {
  },
};
</script>
<style>
/* Estilo de imagens */
.c-img-unidade-faces .v-image__image {
  transition: all 1s ease 0s !important;
}

.u-img-auto .v-image__image {
  background-size: auto !important
}

/* Estilo Contador */
.v-progress-circular {
  margin: 1rem;
}

/* Estilo separador */
.curved {
  position: relative;
  background: #f6f6f6;
  height: 50vh;
  border-bottom-left-radius: 50% 7%;
  border-bottom-right-radius: 50% 7%;
}

/* Estilo tabs cursos */
.c-box-titulos {
  text-transform: initial !important;
  font-size: 25px !important;
  line-height: 28px !important;
}

.c-tab .v-slide-group__content {
  align-items: flex-start;
}

.c-tab-menu {
  font-family: 'Mont';
  font-weight: bolder;
  letter-spacing: 0.5px;
  font-size: 1rem;
  align-items: flex-start !important;
}

#c-img-cursos-tecnicos {
  z-index: 99;
}

#c-tab-cursos .v-item-group,
#c-tab-cursos .v-card,
#c-tab-cursos-2 .v-card,
#c-tab-cursos-2 .v-item-group {
  background: #f6f6f6 !important;
  border-radius: 0px;
}

/* Definições de cabeçalho */
.cabecalho .v-toolbar__content {
  align-items: flex-start;
  height: 100px !important;
}


/* Class gerais */
.u-transicao {
  transition: all 1s ease 0s !important;
}

.u-cursor {
  cursor: pointer;
}

.u-text-initial {
  text-transform: initial !important;
}

.u-elemento-1 {
  z-index: -1;
  top: 1px;
  padding: 86px !important;
}

.u-p-absolute {
  position: absolute;
}

.u-p-relative {
  position: relative;
}

.u-z-index--10 {
  z-index: 10;
}

.u-z-index--20 {
  z-index: 20;
}

.u-z-index--30 {
  z-index: 20;
}

.u-z-index--100 {
  z-index: 100;
}

.v-toolbar__extension {
  justify-content: end;
}

header {
  z-index: 20 !important;
}

.c-app__layout__faculdade_faces {
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
}

/* Estilo de icones */
.c-icon {
  font-size: 28px !important;
}

/* Estilo Botões */
.c-btn-bolsa {
  letter-spacing: 0px;
}

.u-btn-hover {
  transition: ease 300ms;
}

.u-btn-hover:hover {
  transform: scale(1.07);
  transition: ease 300ms;
}

.u-btn-hover .v-btn__content {
  opacity: 1 !important;
}

.c-btn-grandiente {
  background: linear-gradient(to right, #f28e26, #f78330, #fa793a, #fc6e45, #fd644f) !important;
}

.c-btn-cta {
  text-transform: initial;
  background: var(--v-primary-base) !important;
}

.c-btn-cta:hover {
  background: var(--v-secondary-base) !important;
  transition: ease 0.3s;
}

.c-btn-fixed {
  right: 0px !important;
  left: initial !important;
}

/*Costumização do conteiner dos box iniciaias */
.c-row-box {
  margin-top: -202px;
  z-index: 80;
}

/* Typography style for titles, subtitles, paragraphs, highlight titles and buttons. */
.c-box-text {
  font-family: "Mont";
  font-style: normal;
  letter-spacing: 0px;
  font-size: 0.8rem !important;
}

.c-text-menu {
  font-family: "Mont";
  font-style: normal;
  letter-spacing: 0px;
  font-size: 1.5rem;
  font-weight: 700;
}

.c-text-title {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
}

.c-text-sub-title {
  font-family: "Mont";
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.02em;
}

.c-text-highlight {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.05em;
  background: linear-gradient(176deg, #fc1787 -31.01%, #7c2cff 79.3%),
    linear-gradient(205deg, #ae3898 0%, rgba(174, 56, 152, 0.62) 93.35%),
    linear-gradient(315deg,
      #ae3898 0%,
      rgba(228, 49, 104, 0.62) 93.35%,
      rgba(174, 56, 152, 0.62) 93.35%),
    #d1268e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* Definições de tipografia */

.c-titulo-motivo-mobile {
  font-size: 1.8rem;
  font-family: 'Mont';
  font-weight: 500;
  line-height: 2rem;
  text-transform: uppercase;
}

.c-titulo-slide-mobile {
  font-size: 27px;
  font-family: 'Mont';
  font-weight: bold;
  line-height: 27px;
  letter-spacing: -1px;
  background: rgb(240 255 255 / 45%);
}

.c-titulo-slide {
  font-size: 1.9rem;
  font-family: 'Mont';
  font-weight: bold;
  line-height: 1.9rem;
  letter-spacing: -1px;
}

.c-titulo-slide-sub {
  font-family: 'Mont';
  font-weight: 500;
}

.c-titulo-sub {
  font-size: 1.4rem;
  font-family: 'Mont';
  font-weight: 400;
  line-height: 1.5rem;
}

.c-titulo-motivo {
  font-size: 3rem;
  font-family: 'Mont';
  font-weight: 500;
  line-height: 3.2rem;
  text-transform: uppercase;
}

.c-titulo-motivo-sub {
  font-family: 'Mont';
  font-weight: bold;
}

.c-titulo-motivo-2 {
  font-size: 1.5rem;
  font-family: 'Mont';
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;
}

.c-titulo-motivo-sub-2 {
  font-family: 'Mont';
  font-weight: bold;
}

.c-titulo {
  font-size: 2.0rem;
  font-weight: 900;
  line-height: 2.1rem;
  padding-bottom: 5px;
  letter-spacing: -0.3;
  font-family: "Mont";
}

/* footer form */
.c-footer {
  background-image: linear-gradient(to right top, #051937, #00153a, #00113c, #000b3d, #00033e);
}

.c-text-label {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13.795px;
  line-height: 98.12%;
}

.c-text-label input {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13.795px;
  line-height: 98.12%;
  opacity: 0.76;
}

.c-text-label .v-text-field__details {
  margin: 0px !important;
  padding: 0px !important;
  height: 50px;
}

.c-bullet_1_bg {
  background: linear-gradient(0deg, #7833e7, #7833e7), #c4c4c4;
}

.c-bullet_2_bg {
  background: linear-gradient(0deg, #cb2283, #cb2283), #c4c4c4;
}

.c-bullet_1_font_14 {
  font-weight: 500;
  font-size: 14.615px;
  display: block;
}

.c-bullet_1_font_62 {
  font-weight: 500;
  font-size: 62.6452px;
  display: block;
}

.c-bullet_2_font_16 {
  font-weight: 500;
  font-size: 16px;
  display: block;
}

/* background design elements */
#c-sheet {
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
}

/* @media queries > 1440px */
@media only screen and (min-width: 1440px) {
  .c-bullet {
    padding-right: 100px !important;
  }

  .c-btn-fixed {
    right: calc(50% - 1440px / 2) !important;
    left: initial !important;
  }
}

/* @media queries 962px > < 1234px */
@media only screen and (min-width: 961px) and (max-width: 1234px) {
  .c-col-bienvenido h3 {
    margin-top: 40px;
  }

  .c-characters-2 {
    transform: translate(-96px, 10px);
  }
}

/* @media queries 600px > < 960px */
@media only screen and (min-width: 600px) and (max-width: 960px) {}

/* @media queries < 375px */
@media only screen and (max-width: 331px) {
  .c-characters-1 {
    transform: translate(-43px, 0px);
  }

  .c-characters-2 {
    min-width: 360px !important;
    margin-left: -11px;
  }

  .c-names-p--6 {
    right: 41%;
    margin-bottom: 9%;
  }

  .c-names-p--7 {
    right: 10%;
    margin-bottom: 19%;
  }

  .c-names-p--8 {
    right: 26%;
    margin-top: 4%;
  }

  .u-font-size-sub_32 {
    font-size: 28px;
  }
}

@keyframes name-serie {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, 2px);
  }

  80% {
    transform: translate(2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}
</style>
